import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { planPurchaseClick, plansPageView } from '@wix/bi-logger-membership/v2';
import { CheckoutData, encodeBase64Url } from '@wix/pricing-plans-router-utils';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS } from '../../constants';
import { TabState } from '../../constants/settings-events';
import { Analytics } from '../../services/analytics';
import { PlansApi } from '../../services/plans';
import { WarmupData } from '../../services/WarmupData';
import { PopupEnum } from '../../types/common';
import { PlanListInteractions } from '../../types/PlanListFedops';
import { resolveCurrencyLocale } from '../../utils';
import { WIDGET_TYPE } from '../../utils/bi';
import { toError } from '../../utils/errors';
import { hasMultiplePages } from '../../utils/multiple-pages';
import { getOrderedVisiblePlans } from '../../utils/plan-visibility';
import { PageSectionIdMap } from '../PackagePicker/controller/Navigation';
import { SettingsReader } from '../PackagePicker/DefaultSettingsAdapter';
import { ListWidgetProps } from '../PackagePicker/Widget/List';

export class PlanListController {
  constructor(
    public setProps: (props: Partial<ListWidgetProps>) => void,
    protected plansApi: PlansApi,
    protected flowAPI: ControllerFlowAPI,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected appParams: ControllerParams['controllerConfig']['appParams'],
    protected analytics: Analytics,
    protected settings: SettingsReader,
    protected demoPlans: PublicPlan[],
    protected warmupData: WarmupData,
  ) {}

  public async initialize() {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.WidgetLoaded);

    const { plans } = await this.warmupData.cache('widget.plans', () => this.fetchAndOrderPlans());
    this.setProps({
      plans,
      tabState: TabState.REGULAR,
      selectPlan: this.onSelectPlan,
      hidePopup: this.hidePopup,
      popup: null,
      locale: resolveCurrencyLocale(this.wixCodeApi),
    });

    if (this.flowAPI.environment.isViewer) {
      this.flowAPI.bi?.report(
        plansPageView({
          widgetType: WIDGET_TYPE.list,
        }),
      );
    }
    this.analytics.addProductImpression(plans);
  }

  fetchAndOrderPlans = async () => {
    let plans: PublicPlan[] = [];
    try {
      plans = await this.plansApi.loadPaidPlans();
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    const useFixture = !plans?.length && this.wixCodeApi.window.viewMode === 'Editor';
    return { plans: getOrderedVisiblePlans(useFixture ? this.demoPlans : plans, this.settings) };
  };

  private onSelectPlan = async (plan: PublicPlan) => {
    this.flowAPI.fedops.interactionStarted(PlanListInteractions.NavigateToList);
    this.setProps({ selectedPlanId: plan.id });

    if (this.wixCodeApi.window.viewMode !== 'Site') {
      this.setProps({ popup: PopupEnum.checkoutPreview, selectedPlanId: undefined });
      this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
    } else {
      this.navigateToCheckout(plan);
    }
  };

  private navigateToCheckout = async (plan: PublicPlan) => {
    this.flowAPI.bi?.report(planPurchaseClick({ planGuid: plan.id ?? '', widgetType: 'list' }));
    this.analytics.clickProduct(plan);

    const checkoutData: CheckoutData = { planId: plan.id!, integrationData: {} };

    this.setCheckoutPagePath('/payment/' + encodeBase64Url(checkoutData));
  };

  private hidePopup = () => this.setProps({ popup: null });

  private setCheckoutPagePath = async (path: string) => {
    const sectionId =
      this.flowAPI.experiments.enabled(EXPERIMENTS.INSTALL_SPLIT_PAGES) && (await hasMultiplePages(this.wixCodeApi))
        ? PageSectionIdMap.checkout
        : 'membership_plan_picker_tpa';

    const { relativeUrl = '' } = await this.wixCodeApi.site.getSectionUrl({
      appDefinitionId: this.appParams.appDefinitionId,
      sectionId,
    });

    const currentUrl = '/' + this.wixCodeApi.location.path.join('/');
    const disableScrollToTop = currentUrl.startsWith(relativeUrl);
    this.wixCodeApi.location.to!(relativeUrl + path, { disableScrollToTop });
    this.flowAPI.fedops.interactionEnded(PlanListInteractions.NavigateToList);
  };
}
